










import { Component, Vue } from "vue-property-decorator";

@Component
export default class ImposeHtmlPage extends Vue {
  get content() {
    return this.$store.getters["content/imposeContent"];
  }

  created() {
    if (!this.content) {
      this.$router.push({ name: "home" }).catch(e => e);
    }
  }
}
